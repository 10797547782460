<template>
  <div>
    <template v-if="task_branch">
      Филиал: <strong>{{ task_branch.title }}</strong>
    </template>
    <template v-if="task_department">
      Отдел: <strong>{{ task_department.title }}</strong>
    </template>

    <hr class="mt-1 mb-1"/>
  </div>
</template>

<script>
export default {
  name: 'task-item-branch-block',

  props: {
    task_branch: {
      type: Object|null,
    },
    task_department: {
      type: Object|null,
    },
  },
}
</script>

<style scoped>

</style>
